import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ACTIVITIES } from '../../components/constant'
import IMGCSR from '../../images/new/csr.jpeg'
const CSRActivity = () => {
  return (
    <div>
      <Layout>
        <SEO title="CSR Activity" />
        <PageStructure
          title="CSR Activity"
          desc="TESTIG"
          mode={ACTIVITIES}
          image={IMGCSR}
        />
      </Layout>
    </div>
  )
}

export default CSRActivity
